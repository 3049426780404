@media only screen and (max-width: 950px) {
  .bio-section {
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url("./../../../../images/backgrounds/3_Blank.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: 45%;
    min-height: 1356px;
  }

  .bio-section-splatter-1 {
    background-image: url("./../../../../images/page_elements/Splash_Cropped_4.svg");
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: -400px;
    margin-bottom: 100px;
    width: 184px;
    height: 305px;
    z-index: 1;
  }

  .bio-section-splatter-2 {
    background-image: url("./../../../../images/page_elements/Splash_Cropped_5_5.svg");
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: auto;
    width: 180px;
    height: 190px;
    z-index: 1;
  }

  .bio-section-button-wrapper {
    padding: 5vw;
  }

  .bio-section-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 650px;
    margin-top: -80px;
    margin-bottom: 60px;
    margin-left: 10vw;
    margin-right: 10vw;
  }

  .bio-header-and-intro-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 600px;
  }

  .bio-header-img {
    display: flex;
    align-self: center;
    background-image: url("./../../../../images/page_elements/Bio_Header.svg");
    background-size: contain;
    background-repeat: no-repeat;
    height: 65px;
    width: 65px;
    z-index: 1;
  }

  .bio-mobile-intro-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0vw 2vw;
  }

  .bio-intro {
    color: white;
    text-align: center;
    z-index: 1;
  }

  .bio-shifter-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 6vw;
    margin-top: 6vw;
    padding: 0vw 8vw;
  }

  .bio-shifter-content-wrapper {
    animation: fadeIn 3.5s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .bio-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    user-select: none;
    padding: 0vw 2vw;
    width: 61vw;
    height: auto;
  }

  .bio-item-text-wrapper {
    display: flex;
    flex-direction: column;
  }

  .bio-item-header {
    display: flex;
    color: white;
  }

  .bio-text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0;
    height: 400px;
    min-width: 214px;
  }

  .bio-text-mobile-members {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: "SpaceGrotesk-Regular";
    margin: 0;
    height: 400px;
    min-width: 214px;
  }

  .bio-section-mobile-shift-carousel-forward-button {
    background-image: url("./../../../../images/page_elements/Karuselli_Arrow_Left.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: 50%;
    padding-left: 20px;
    cursor: pointer;
    height: 50px;
    transform: rotate(-90deg);
    width: 25px;
    z-index: 10;
  }

  .bio-desktop-intro-wrapper {
    display: none;
  }
}

@media only screen and (min-width: 950px) {
  .bio-section {
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url("./../../../../images/backgrounds/3_Blank.webp");
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    min-height: 1600px;
  }

  .bio-section-splatter-1 {
    display: none;
  }

  .bio-section-splatter-2 {
    display: none;
  }

  .bio-section-content-wrapper {
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    justify-content: center;
    margin: -100px 0px 0px 0px;
    z-index: 1;
  }

  .bio-header-and-intro-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 0px 50px 0px;
  }

  .bio-header-img {
    background-image: url("./../../../../images/page_elements/Bio_Header.svg");
    background-size: contain;
    background-repeat: no-repeat;
    height: 60px;
    width: 50px;
    margin-bottom: 20px;
  }

  .bio-mobile-intro-wrapper {
    display: none;
  }

  .bio-intro {
    color: white;
    text-align: center;
    z-index: 1;
  }

  .bio-shifter-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 0vw;
    margin-top: 0vw;
    padding: 0vw 0vw;
  }

  .bio-shifter-content-wrapper {
    animation: fadeIn 3.5s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .bio-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    user-select: none;
    padding: 2vw;
    width: 61vw;
    height: auto;
  }

  .bio-item-text-wrapper {
    display: flex;
    flex-direction: column;
  }

  .bio-item-header {
    display: flex;
    color: white;
  }

  .bio-section-shift-carousel-backwards-button {
    background-image: url("./../../../../images/page_elements/Karuselli_Arrow_Left.svg");
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    margin-right: 0px;
    height: 50px;
    transform: rotate(90deg);
    width: 25px;
  }

  .bio-section-shift-carousel-forward-button {
    background-image: url("./../../../../images/page_elements/Karuselli_Arrow_Left.svg");
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    margin-top: auto;
    height: 50px;
    transform: rotate(-90deg);
    width: 25px;
  }
}

@media only screen and (min-width: 900px) {
  .bio-desktop-intro-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
    padding-top: 90px;
  }

  .bio-desktop-text-and-gallery-wrapper {
    display: flex;
    flex-direction: row;
    gap: 50px;
    width: 837px;
    height: 400px;
  }

  .bio-desktop-text-wrapper {
    display: flex;
    color: white;
    font-size: small;
    text-align: left;
    margin: 0px 0px;
    width: 100%;
  }

  .bio-desktop-image-header {
    margin: 0;
    color: white;
  }

  .bio-desktop-band-members {
    display: none;
    flex: 1;
    color: white;
    font-size: small;
    text-align: left;
    margin-top: 40px;
    margin-bottom: 500px;
  }

  .bio-desktop-image-gallery-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 0px;
  }

  /* Gallery Layout */
  .bio-desktop-image-gallery {
    display: grid;
    grid-template-columns: repeat(
      3,
      1fr
    ); /* This will create 4 columns of equal width. Adjust the number as needed. */
    gap: 20px; /* Spacing between images */
    justify-content: center; /* Horizontal centering */
    align-content: center; /* Vertical centering */
  }

  /* Parent container */
  .bio-desktop-image-item {
    overflow: hidden;
    position: relative;
    cursor: pointer;
    transition: transform 0.3s ease;
    width: 200px; /* Adjust to your desired width */
    height: 100%; /* Adjust to your desired height */
    overflow: hidden; /* Hide overflow */
  }

  /* Image grows on hover */
  .bio-desktop-image-item:hover {
    transform: scale(1.1); /* Enlarge the image on hover */
  }

  /* Image inside the container */
  .bio-desktop-image-picture {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the container */
    transition: transform 0.3s ease; /* Smooth grow */
  }

  /* Image grows slightly */
  .bio-desktop-image-item:hover .bio-desktop-image-picture {
    transform: scale(1.05); /* Slightly enlarge the image */
  }

  .bio-image-hover-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 15px;
    font-family: "SpaceGrotesk-Regular";
    text-align: center;
    opacity: 0;
    transition: opacity 0.5s ease;
    z-index: 2;
  }

  .bio-desktop-image-item:hover .bio-image-hover-text {
    opacity: 1;
  }

  .bio-desktop-text-and-button-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 100%;
  }

  .bio-section-desktop-shift-carousel-forward-button {
    display: none;
    background-image: url("./../../../../images/page_elements/Karuselli_Arrow_Left.svg");
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    height: 50px;
    transform: rotate(-90deg);
    width: 25px;
  }

  .bio-text {
    margin: 0 !important;
    font-size: 15px;
  }

  .bio-desktop-text-button-wrapper {
    display: flex;
    justify-content: left;
    width: 100%;
    padding-left: 25px;
    margin-top: auto;
  }
}

@media only screen and (min-width: 1367px) {
}

@media only screen and (min-width: 1680px) {
}

@media only screen and (min-width: 2100px) {
  .bio-section {
    min-height: 1800px;
  }

  .bio-header-img {
    margin-top: -300px;
  }
}

@media only screen and (min-width: 2561px) {
}

@media only screen and (min-width: 3620px) {
}

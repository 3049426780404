@media only screen and (max-width: 381px) {
  .tour-section {
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url("./../../../../images/backgrounds/4_Blank.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: 25%;
    height: 100%;
  }

  .tour-section-content-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;
    margin-bottom: 200px;
    margin-top: -100px;
    min-height: 400px;
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  .tour-header-img {
    background-image: url("./../../../../images/page_elements/Tour_Header.svg");
    background-size: contain;
    background-repeat: no-repeat;
    height: 63px;
    width: 107px;
  }

  .upcoming-shows-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 0 2%;
    max-width: 80%;
    margin-bottom: 80px;
  }

  .tour-title {
    color: #ff9900;
    display: flex;
    align-self: center;
  }

  .tour-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    border-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0),
        orange,
        rgba(0, 0, 0, 0)
      )
      1;
    border-width: 0px 0px 2px 0px;
    border-style: solid;
    min-width: 200px;
    padding-bottom: 30px;
    white-space: nowrap;
  }

  .gig-date {
    color: white;
    margin: 0;
  }

  .gig-location {
    color: #ff9900;
    margin: 0;
  }

  .buy-tickets-button {
    align-self: center;
    border: 2px solid #ff9900;
    border-radius: 12px;
    color: white;
    display: flex;
    padding: 3px 20px;
    margin: 0;
  }

  .tour-tba {
    color: #ff9900;
  }

  .tour-text {
    color: white;
    text-align: center;
    margin: 0 10vw;
  }
}

@media only screen and (min-width: 381px) {
  .tour-section {
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url("./../../../../images/backgrounds/4_Blank.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: 25%;
    margin-top: -200px;
  }

  .tour-section-content-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;
    margin-top: -100px;
    margin-bottom: 300px;
    width: 100%;
    z-index: 1;
  }

  .tour-header-img {
    background-image: url("./../../../../images/page_elements/Tour_Header.svg");
    background-size: contain;
    background-repeat: no-repeat;
    height: 80px;
    width: 100px;
  }

  .tour-text {
    color: white;
    font-size: small;
    text-align: center;
    margin: 0 10vw;
  }

  .upcoming-shows-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 0 2%;
    max-width: 80%;
    margin-bottom: 80px;
  }

  .tour-title {
    color: #ff9900;
    display: flex;
    align-self: center;
  }

  .tour-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    border-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0),
        orange,
        rgba(0, 0, 0, 0)
      )
      1;
    border-width: 0px 0px 2px 0px;
    border-style: solid;
    min-width: 200px;
    padding-bottom: 30px;
    white-space: nowrap;
  }

  .gig-date {
    color: white;
    margin: 0;
  }

  .gig-location {
    color: #ff9900;
    margin: 0;
  }

  .buy-tickets-button {
    align-self: center;
    border: 2px solid #ff9900;
    border-radius: 12px;
    color: white;
    display: flex;
    padding: 3px 20px;
    margin: 0;
  }

  .tour-tba {
    color: #ff9900;
  }

  .tour-text {
    color: white;
    font-size: small;
    text-align: center;
    margin: 0 10vw;
  }
}

@media only screen and (min-width: 500px) {
  .tour-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    border-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0),
        orange,
        rgba(0, 0, 0, 0)
      )
      1;
    border-width: 0px 0px 2px 0px;
    border-style: solid;
    min-width: 200px;
    padding-bottom: 30px;
    white-space: nowrap;
  }

  .buy-tickets-button {
    margin-left: auto;
  }
}

@media only screen and (min-width: 950px) {
  @keyframes breatheSplatter1 {
    0% {
      transform: scale(1) translateX(0); /* Start at normal size and position */
    }
    50% {
      transform: scale(1.1) translateX(-10px); /* Gently grow and shift slightly left */
    }
    100% {
      transform: scale(1) translateX(0); /* Smoothly return to original size and position */
    }
  }

  .tour-section {
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url("./../../../../images/backgrounds/4_Blank.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: 0%;
    min-height: 800px;
  }

  .tour-header-img {
    width: 80px;
    height: 60px;
  }

  .tour-title {
    margin: 0;
  }

  .tour-section-splatter-1 {
    background-image: url("./../../../../images/page_elements/Splash_Cropped_5.svg");
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: auto;
    margin-top: -500px;
    width: 300px;
    height: 500px;
    z-index: 1;
    animation: breatheSplatter1 15s ease-in-out infinite;
  }

  .tour-section-splatter-2 {
    background-image: url("./../../../../images/page_elements/Splash_Cropped_4.svg");
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -300px;
    width: 300px;
    height: 400px;
    z-index: 1;
  }

  .tour-section-content-wrapper {
    display: flex;
    justify-content: center;
    z-index: 1;
    margin-top: 0px;
  }

  .tour-text {
    color: white;
    font-size: 15px;
    text-align: center;
    margin: 0 10vw;
  }
}

@media only screen and (min-width: 1367px) {
}

@media only screen and (min-width: 1680px) {
}

@media only screen and (min-width: 2100px) {
  .tour-section {
    min-height: 1600px;
  }

  .tour-header-img {
    margin-top: -150px;
  }
}

@media only screen and (min-width: 2561px) {
}

@media only screen and (min-width: 3620px) {
}

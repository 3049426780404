@media only screen and (max-width: 650px) {
  .contact-section {
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url("./../../../../images/backgrounds/7_Blank.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: 45%;
    min-height: 900px;
  }

  .contact-header-img {
    display: flex;
    align-self: center;
    background-image: url("./../../../../images/page_elements/Contact_Header.svg");
    background-size: contain;
    background-repeat: no-repeat;
    height: 52px;
    width: 150px;
    z-index: 1;
  }

  .contact-info-wrapper {
    display: flex;
    align-items: flex-start;
  }

  .contact-section-content-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: -100px;
    margin-bottom: 125px;
    gap: 20px;
    z-index: 1;
  }

  .contact-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .contact-social-medias {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    justify-content: center;
  }

  .get-connected-header {
    color: #ff9900;
  }

  .contact-item-header {
    display: none;
    color: #ff9900;
  }

  .get-connected-icons {
    display: flex;
    flex-direction: row;
    gap: 55px;
  }

  .contact-item-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .contact-item-info {
    color: white;
    border-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0),
        orange,
        rgba(0, 0, 0, 0)
      )
      1;
    border-width: 0px 0px 2px 0px;
    border-style: solid;
    min-width: 200px;
    padding-bottom: 30px;
  }

  .contact-social-media-icon {
    width: 60px;
    height: 60px;
  }

  .press-section {
    display: none;
    flex-direction: column;
    align-items: center;
  }

  .press-header {
    color: #ff9900;
  }

  .press-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 40px;
  }

  .header-and-donwload-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .press-content-header {
    color: #ff9900;
  }

  .press-download-all {
    color: #ff9900;
  }

  .press-download-categories {
    display: flex;
    flex-direction: row;
    gap: 25px;
    margin-top: 20px;
    width: 100%;
  }

  .download-content-button {
    border: 2px solid #ff9900;
    border-radius: 12px;
    color: white;
    padding: 3px 15px;
  }
}

@media only screen and (min-width: 650px) {
  .contact-section {
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url("./../../../../images/backgrounds/7_Blank.webp");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .contact-header-img {
    display: flex;
    align-self: center;
    background-image: url("./../../../../images/page_elements/Contact_Header.svg");
    background-size: contain;
    background-repeat: no-repeat;
    height: 80px;
    width: 125px;
    z-index: 1;
  }

  .contact-info-wrapper {
    display: flex;
    align-items: flex-start;
  }

  .contact-section-content-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: -100px;
    margin-bottom: 125px;
    gap: 20px;
    z-index: 1;
  }

  .contact-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .contact-social-medias {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    justify-content: center;
  }

  .get-connected-header {
    color: #ff9900;
  }

  .contact-item-header {
    display: none;
    color: #ff9900;
  }

  .get-connected-icons {
    display: flex;
    flex-direction: row;
    gap: 55px;
  }

  .contact-item-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
  }

  .contact-item-info {
    color: white;
    border-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0),
        orange,
        rgba(0, 0, 0, 0)
      )
      1;
    border-width: 0px 0px 2px 0px;
    border-style: solid;
    min-width: 200px;
    padding-bottom: 30px;
  }

  .contact-social-media-icon {
    width: 60px;
    height: 60px;
  }

  .press-section {
    display: none;
    flex-direction: column;
    align-items: center;
  }

  .press-header {
    color: #ff9900;
  }

  .press-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 40px;
  }

  .header-and-donwload-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .press-content-header {
    color: #ff9900;
  }

  .press-download-all {
    color: #ff9900;
  }

  .press-download-categories {
    display: flex;
    flex-direction: row;
    gap: 25px;
    margin-top: 20px;
    width: 100%;
  }

  .download-content-button {
    border: 2px solid #ff9900;
    border-radius: 12px;
    color: white;
    padding: 3px 15px;
  }
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1367px) {
}

@media only screen and (min-width: 1680px) {
}

@media only screen and (min-width: 2100px) {
}

@media only screen and (min-width: 2561px) {
}

@media only screen and (min-width: 3620px) {
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*.home-section div * {
  outline: 1px solid #f00 !important;
}*/

@media only screen and (max-width: 650px) {
  .home-section {
    display: flex;
    flex-direction: column;
    background-image: url("./../../../../images/backgrounds/mobile-hero-picture.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: 35%;
    height: 100vh;
    overflow: hidden;
    margin-top: 100px;
  }

  .home-section-splatter-1 {
    animation: fadeIn 8.5s;
    background-image: url("./../../../../images/page_elements/Splash_Cropped_1.svg");
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: auto;
    margin-top: -50px;
    margin-right: -90px;
    width: 455px;
    height: 305px;
    z-index: 1;
  }

  .home-section-splatter-2 {
    animation: fadeIn 5.5s;
    background-image: url("./../../../../images/page_elements/Splash_Cropped_2.svg");
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 250px;
    width: 280px;
    height: 388px;
    z-index: 1;
  }

  .home-section-content-wrapper {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 50px;
    z-index: 1;
  }

  .home-section-content-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    z-index: 1;
  }

  .home-section-carousel-wrapper {
    animation: fadeIn 3.5s;
    display: flex;
    flex-direction: row;
  }

  .home-section-carousel-content-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 150px;
  }

  .home-section-carousel-content-wrapper
    .home-section-carousel-showcase-card:nth-child(1) {
    display: flex;
  }

  .home-section-carousel-content-wrapper
    .home-section-carousel-showcase-card:nth-child(2) {
    display: none;
  }

  .home-section-carousel-content-wrapper
    .home-section-carousel-showcase-card:nth-child(3) {
    display: none;
  }

  .home-section-carousel-content-wrapper
    .home-section-carousel-showcase-card:nth-child(4) {
    display: none;
  }

  .home-section-carousel-showcase-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    user-select: none;
    width: 230px;
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease;
  }

  .home-section-carousel-showcase-card img:hover {
    transform: scale(1.1);
  }

  .home-section-carousel-showcase-card-header {
    color: #ff9900;
    margin-top: 40px;
  }

  .home-section-carousel-showcase-card-text {
    color: white;
  }

  .home-section-shift-carousel-forward-button {
    background-image: url("./../../../../images/page_elements/Karuselli_Arrow_Left.svg");
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    height: 50px;
    width: 50px;
    margin-top: 230px;
    transform: rotate(180deg);
  }

  .home-section-shift-carousel-backwards-button {
    background-image: url("./../../../../images/page_elements/Karuselli_Arrow_Left.svg");
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    min-width: 50px;
    height: 50px;
    width: 50px;
    margin-top: 230px;
  }
}

@media only screen and (min-width: 650px) {
  .home-section {
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("./../../../../images/backgrounds/1_blank.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 47% 50%;
    height: 100vh;
    width: 100%;
    overflow: hidden;
  }

  .home-section:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: url("./../../../../images/backgrounds/1_blank.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 47% 50%;
    height: 100vh;
    width: 100%;
    opacity: 0.2;
    mix-blend-mode: hard-light;
  }

  .home-section:hover:before {
    animation: animate 10.2s linear infinite;
  }

  @keyframes animate {
    0% {
      background-position: 47% 50%;
      filter: hue-rotate(0deg);
    }
    20% {
      background-position: calc(47% + 5px) 50%;
    }
    40% {
      background-position: calc(47% - 5px) 50%;
    }
    45% {
      background-position: calc(47% + 10px) 50%;
    }
    55% {
      background-position: calc(47% + 5px) 50%;
    }
    100% {
      background-position: 47% 50%;
      filter: hue-rotate(0deg);
    }
  }

  .home-section-splatter-1 {
    background-image: url("./../../../../images/page_elements/Splash_Cropped_1.svg");
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: auto;
    margin-right: -100px;
    width: 512px;
    height: 356px;
  }

  .home-section-splatter-2 {
    background-image: url("./../../../../images/page_elements/Splash_Cropped_2.svg");
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 0px;
    margin-bottom: -100px;
    width: 300px;
    height: 512px;
    z-index: 1;
  }

  .home-section-content-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 200px;
    z-index: 1;
  }

  .home-section-carousel-wrapper {
    animation: fadeIn 3.5s;
    display: flex;
    flex-direction: row;
    margin-left: 50px;
    margin-right: 50px;
  }

  .home-section-carousel-content-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 20px;
    margin-top: 150px;
  }

  .home-section-carousel-content-wrapper
    .home-section-carousel-showcase-card:nth-child(3) {
    display: none;
  }

  .home-section-carousel-content-wrapper
    .home-section-carousel-showcase-card:nth-child(4) {
    display: none;
  }

  .home-section-carousel-showcase-card {
    display: flex;
    flex-direction: column;
    user-select: none;
    justify-content: center;
    width: 230px;
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease;
  }

  .home-section-carousel-showcase-card img:hover {
    transform: scale(1.1);
  }

  .home-section-carousel-showcase-card-header {
    color: #ff9900;
    margin-top: 40px;
  }

  .home-section-carousel-showcase-card-text {
    color: white;
  }

  .home-section-shift-carousel-forward-button {
    background-image: url("./../../../../images/page_elements/Karuselli_Arrow_Left.svg");
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    height: 50px;
    width: 50px;
    margin-top: 230px;
    transform: rotate(180deg);
  }

  .home-section-shift-carousel-backwards-button {
    background-image: url("./../../../../images/page_elements/Karuselli_Arrow_Left.svg");
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    min-width: 50px;
    height: 50px;
    width: 50px;
    margin-top: 230px;
  }
}

@media only screen and (min-width: 650px) and (max-height: 450px) {
  .home-section {
    background-image: url("./../../../../images/backgrounds/mobile-hero-picture.webp");
    background-size: cover;
    height: 100%;
    width: 100%;
  }

  .home-section:before {
    display: none;
  }
}

@media only screen and (min-width: 900px) {
  @keyframes animate {
    0% {
      background-position: 45% 50%;
      filter: hue-rotate(0deg);
    }
    20% {
      background-position: calc(45% + 5px) 50%;
    }
    40% {
      background-position: calc(45% - 5px) 50%;
    }
    45% {
      background-position: calc(45% + 10px) 50%;
    }
    55% {
      background-position: calc(45% + 5px) 50%;
    }
    100% {
      background-position: 45% 50%;
      filter: hue-rotate(0deg);
    }
  }

  .home-section {
    background-position: 45% 50%;
  }

  .home-section:before {
    background-position: 45% 50%;
  }

  .home-section-carousel-showcase-card {
    width: 230px;
  }

  .home-section-carousel-content-wrapper
    .home-section-carousel-showcase-card:nth-child(3) {
    display: flex;
  }

  .home-section-carousel-content-wrapper
    .home-section-carousel-showcase-card:nth-child(4) {
    display: none;
  }
}

@media only screen and (min-width: 650px) and (min-height: 1024px) {
  .home-section-splatter-2 {
    margin-top: 400px;
    width: 300px;
    height: 512px;
  }
}

@media only screen and (min-width: 1280px) and (min-height: 1024px) {
  .home-section-splatter-2 {
    margin-top: 200px;
    width: 300px;
    height: 512px;
  }
}

@media only screen and (min-width: 1280px) and (max-height: 720px) {
  .home-section-splatter-2 {
    margin-top: -100px;
    width: 300px;
    height: 512px;
  }
}

@media only screen and (min-width: 1367px) {
  .home-section {
    background-position: 45% 50%;
  }

  .home-section:before {
    background-position: 45% 50%;
  }

  @keyframes animate {
    0% {
      background-position: 45% 50%;
      filter: hue-rotate(0deg);
    }
    20% {
      background-position: calc(45% + 5px) 50%;
    }
    40% {
      background-position: calc(45% - 5px) 50%;
    }
    45% {
      background-position: calc(45% + 10px) 50%;
    }
    55% {
      background-position: calc(45% + 5px) 50%;
    }
    100% {
      background-position: 45% 50%;
      filter: hue-rotate(0deg);
    }
  }

  .home-section-splatter-1 {
    margin-right: -150px;
    width: 640px;
    height: 320px;
  }

  .home-section-splatter-2 {
    width: 640px;
    height: 640px;
  }

  .home-section-carousel-showcase-card {
    width: 230px;
  }

  .home-section-carousel-content-wrapper
    .home-section-carousel-showcase-card:nth-child(3) {
    display: flex;
  }

  .home-section-carousel-content-wrapper
    .home-section-carousel-showcase-card:nth-child(4) {
    display: flex;
  }
}

@media only screen and (min-width: 1680px) {
}

@media only screen and (min-width: 1680px) and (min-height: 1200px) {
  .home-section-splatter-2 {
    margin-top: 200px;
    width: 600px;
    height: 600px;
  }
}

@media only screen and (min-width: 1680px) and (min-height: 1440px) {
  .home-section-splatter-2 {
    margin-top: 350px;
    width: 600px;
    height: 600px;
  }
}

@media only screen and (min-width: 2100px) {
}

@media only screen and (min-width: 2561px) {
}

@media only screen and (min-width: 3620px) {
}

/*fadeIn*/

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes moveLeftAndBack {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-10px); /* Adjust this value as needed */
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes moveUpAndDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px); /* Adjust this value as needed */
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes moveDownAndUp20px {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px); /* Adjust this value as needed */
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes moveUpAndDown20px {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px); /* Adjust this value as needed */
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes scaleUpAndDown {
  0%,
  100% {
    transform: scale(1); /* Starts at normal size */
  }
  50% {
    transform: scale(1.05); /* Enlarges slightly */
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.twist-border {
  --g: 4px; /* the gap */
  --b: 3px; /* border thickness*/
  --c: #ff9900; /* the color */

  padding: calc(var(--g) + var(--b));
  --_c: #0000 0 25%, var(--c) 0 50%;
  --_g1: repeating-linear-gradient(90deg, var(--_c)) repeat-x;
  --_g2: repeating-linear-gradient(180deg, var(--_c)) repeat-y;
  background: var(--_g1) var(--_p1, 175%) 0, var(--_g2) 0 var(--_p2, 75%),
    var(--_g1) var(--_p2, 75%) 100%, var(--_g2) 100% var(--_p1, 175%);
  background-size: 200% var(--b), var(--b) 200%;
  filter: grayscale(0%);
  transition: 0.8s;
}

/*.twist-border:hover,
.twist-border.hovered {
  --_p: 125%;
  filter: grayscale(0%);
}*/

/*section {
  outline: 1px solid rgb(51, 255, 0) !important;
}*/

.fade-effect {
  opacity: 1;
  transition: opacity 0.5s ease; /* 0.5s is the duration of the fade. Adjust as needed. */
  cursor: pointer; /* To indicate it's clickable */
}

/* Faded state: fully transparent */
.faded {
  opacity: 0;
}

.modal {
  position: absolute;
  left: 0;
  top: 200px;
  width: 100%; /* Set to your desired width */
  height: 600px; /* Set to your desired height */
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1000; /* Ensure it's on top of other content */
}

.modal img {
  width: 1000px; /* Set to your desired width */
  height: 500px; /* Set to your desired height */
  object-fit: cover; /* This will ensure the image covers the container without distortion */
}

/* ------------------------------------- Utility classes ------------------------------------- */

.grabbable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.break {
  flex-basis: 100%;
  height: 0;
}

/* ------------------------------------- App, Nav, General ------------------------------------- */

.App {
  text-align: center;
  overflow: hidden;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.landing-page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #111111;
  /*max-width: 1366px;*/
}

.nav-links-wrapper {
  color: white;
  display: flex;
  flex-direction: row;
  gap: 82px;
  padding-left: 0px;
  user-select: none;
}

.nav-links-wrapper li:hover {
  color: #ff9900;
  cursor: pointer;
}

.nav-links-text {
  margin: 0;
}

.nav-links-wrapper li.active h5 {
  color: orange;
}

.active-section {
  color: blue;
}

.section-wrapper {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #ff9900;
}

.footer-splatter-yellow {
  background-image: url("./../images/page_elements/Footer_Splash_Yellow.svg");
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: -160px;
  width: 280px;
  height: 157px;
  position: relative;
  z-index: 1;
}

.footer-splatter-black {
  background-image: url("./../images/page_elements/Footer_Splash_Black.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 300px;
  height: 100px;
  position: relative;
  z-index: 1;
}

/* ------------------------------------- Media screens ------------------------------------- */

/* Extra small devices (phones, 650px and down) */
@media only screen and (max-width: 650px) {
  .header-unscrolled {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: -100px;
    height: 80px;
    background: #111111;
    position: sticky;
    top: 0px;
    z-index: 2;
  }

  .header-scrolled {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: -100px;
    height: 80px;
    background: #111111;
    position: sticky;
    top: 0px;
    z-index: 2;
  }

  .burger-icon div {
    color: white;
    display: block;
    position: absolute;
    left: 0;
    top: 1;
    font-size: 36px;
    padding-left: 20px;
    padding-top: 20px;
  }

  .mobile-nav {
    position: fixed;
    top: -100%; /* Start off-screen */
    left: 0;
    width: 100%;
    background-color: #111111;
    z-index: 2000;
    border-radius: 0;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    transition: top 0.3s ease-out; /* This provides the slide-down effect */
  }

  .mobile-nav.active {
    top: 65px; /* Adjust based on your navbar height */
  }

  .mobile-nav ul {
    list-style-type: none;
    padding: 0;
    text-align: center;
  }

  .mobile-nav li {
    margin: 10px 0;
    font-size: 1.5rem;
    color: white;
  }

  .mobile-nav li:hover {
    cursor: pointer;
    color: orange;
  }

  .navigation-bar {
    display: none;
  }

  .humavoid-mobile-logo {
    height: 50px;
    width: 221px;
    background-image: url("./../images/page_elements/Logo_Hero.svg");
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: 20px;
    margin-top: 15px;
    z-index: 3;
  }

  .smoke-1 {
    height: 800px;
    margin-top: -500px;
    background-image: url("./../images/backgrounds/smokekaari.webp");
    background-size: cover;
    background-position-x: 47%;
    background-repeat: no-repeat;
  }

  .smoke-1-parallel-up {
    animation: moveUpAndDown 10s infinite;
    height: 800px;
    margin-top: -500px;
    background-image: url("./../images/backgrounds/smokekaari.webp");
    background-size: cover;
    background-position-x: 47%;
    background-repeat: no-repeat;
    object-fit: cover;
  }

  .smoke-1-parallel-down {
    height: 800px;
    margin-top: -500px;
    background-image: url("./../images/backgrounds/smokekaari.webp");
    background-size: cover;
    background-position-x: 47%;
    background-repeat: no-repeat;
    object-fit: cover;
  }

  .smoke-2 {
    height: 800px;
    margin-top: -380px;
    background-image: url("./../images/backgrounds/smokekupera.webp");
    background-size: cover;
    background-position-x: 44%;
    background-repeat: no-repeat;
  }

  .smoke-2-parallel-down {
    animation: moveDownAndUp20px 20s infinite;
    height: 800px;
    margin-top: -470px;
    background-image: url("./../images/backgrounds/smokekupera.webp");
    background-size: cover;
    background-position-x: 44%;
    background-repeat: no-repeat;
    object-fit: cover;
  }

  .smoke-2-parallel-up {
    animation: moveUpAndDown20px 20s infinite;
    height: 800px;
    margin-top: -460px;
    background-image: url("./../images/backgrounds/smokekupera.webp");
    background-size: cover;
    background-position-x: 44%;
    background-repeat: no-repeat;
    object-fit: cover;
  }

  .hp-section {
  }
}

/* Small devices (portrait tablets and large phones, 650px and up) */
@media only screen and (min-width: 650px) {
  .twist-border {
    --g: 4px; /* the gap */
    --b: 2px; /* border thickness*/
    --c: #ff990000; /* the color */

    padding: calc(var(--g) + var(--b));
    --_c: #00000000 0 25%, var(--c) 0 50%;
    --_g1: repeating-linear-gradient(90deg, var(--_c)) repeat-x;
    --_g2: repeating-linear-gradient(180deg, var(--_c)) repeat-y;
    background: var(--_g1) var(--_p, 75%) 0, var(--_g2) 0 var(--_p, 75%),
      var(--_g1) var(--_p, 75%) 100%, var(--_g2) 100% var(--_p, 75%);
    background-size: 200% var(--b), var(--b) 200%;
    filter: grayscale(0%);
    transition: 0.3s;
  }
  .twist-border:hover {
    --_p: 75%;
    filter: grayscale(0%);
  }

  .burger-icon div {
    display: none;
  }

  .header-unscrolled {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: -287px;
    background: transparent;
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .header-scrolled {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden; /* Chrome, Safari, Opera */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: -287px;
    background: #111111;
    position: sticky;
    top: -2px;
    z-index: 2;
  }

  .nav-links-wrapper {
    gap: 40px;
  }

  .humavoid-logo {
    display: flex;
    align-self: center;
    height: 160px;
    width: 501px;
    background-image: url("./../images/page_elements/Logo_Hero.svg");
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 54px;
    z-index: 2;
  }

  .humavoid-mobile-logo {
    display: none;
  }

  .smoke-1-parallel-up {
    height: 800px;
    margin-top: -480px;
    background-image: url("./../images/backgrounds/smokekaari.webp");
    background-size: cover;
    background-position-x: 47%;
    background-repeat: no-repeat;
    object-fit: cover;
  }

  .smoke-1-parallel-down {
    height: 800px;
    margin-top: -480px;
    background-image: url("./../images/backgrounds/smokekaari.webp");
    background-size: cover;
    background-position-x: 47%;
    background-repeat: no-repeat;
    object-fit: cover;
  }

  .smoke-2-parallel-down {
    height: 800px;
    margin-top: -470px;
    background-image: url("./../images/backgrounds/smokekupera.webp");
    background-size: cover;
    background-position-x: 44%;
    background-repeat: no-repeat;
    object-fit: cover;
  }

  .smoke-2-parallel-up {
    height: 800px;
    margin-top: -460px;
    background-image: url("./../images/backgrounds/smokekupera.webp");
    background-size: cover;
    background-position-x: 44%;
    background-repeat: no-repeat;
    object-fit: cover;
  }

  .hp-section {
  }
}

@media only screen and (min-width: 650px) and (max-height: 450px) {
  .smoke-1 {
    height: 900px;
    margin-top: -520px;
  }

  .smoke-2 {
    height: 800px;
    margin-top: -380px;
  }
}

@media only screen and (min-width: 900px) {
  /*Visual divider*/
  .section-divider {
    border-style: solid;
    margin: 30px 0;
  }

  /* Default bottom border */
  .section-divider-bottom {
    border-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0),
        orange,
        rgba(0, 0, 0, 0)
      )
      1;
    border-width: 0px 0px 2px 0px; /* Bottom border */
  }

  /* Optional top border */
  .section-divider-top {
    border-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0),
        orange,
        rgba(0, 0, 0, 0)
      )
      1;
    border-width: 2px 0px 0px 0px; /* Top border */
  }

  .section-divider-both {
    border-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0),
        orange,
        rgba(0, 0, 0, 0)
      )
      1;
    border-width: 2px 0px 2px 0px; /* Top border */
  }
}

/* Extra large devices (large laptops and desktops, 1367px and up) */
@media only screen and (min-width: 1367px) {
  .nav-links-wrapper {
    gap: 82px;
  }

  .header-unscrolled {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: -287px;
    background: transparent;
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .header-scrolled {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: -287px;
    background: #111111;
    position: sticky;
    top: -2px;
    z-index: 2;
  }

  .humavoid-logo {
    width: 600px;
  }

  .smoke-1-parallel-up {
    min-height: 1000px;
    margin-top: -580px;
    background-image: url("./../images/backgrounds/smokekaari.webp");
    background-size: cover;
    background-position-x: 90%;
    background-repeat: no-repeat;
    object-fit: cover;
  }

  .smoke-2-parallel-down {
    height: 1000px;
    margin-top: -580px;
    background-image: url("./../images/backgrounds/smokekupera.webp");
    background-size: cover;
    background-position-x: 90%;
    background-repeat: no-repeat;
    object-fit: cover;
  }

  .smoke-1-parallel-down {
    height: 800px;
    margin-top: -500px;
    background-image: url("./../images/backgrounds/smokekaari.webp");
    background-size: cover;
    background-position-x: 47%;
    background-repeat: no-repeat;
    object-fit: cover;
  }

  .smoke-2-parallel-up {
    height: 800px;
    margin-top: -480px;
    background-image: url("./../images/backgrounds/smokekupera.webp");
    background-size: cover;
    background-position-x: 44%;
    background-repeat: no-repeat;
    object-fit: cover;
  }

  .hp-section {
  }
}

@media only screen and (min-width: 1536px) {
  .smoke-2-parallel-up {
    height: 800px;
    margin-top: -520px;
    background-image: url("./../images/backgrounds/smokekupera.webp");
    background-size: cover;
    background-position-x: 44%;
    background-repeat: no-repeat;
    object-fit: cover;
  }

  .smoke-1-parallel-down {
    height: 800px;
    margin-top: -520px;
    background-image: url("./../images/backgrounds/smokekaari.webp");
    background-size: cover;
    background-position-x: 47%;
    background-repeat: no-repeat;
    object-fit: cover;
  }
}

@media only screen and (min-width: 1680px) {
  .smoke-1-parallel-up {
    min-height: 1070px;
    margin-top: -620px;
    background-image: url("./../images/backgrounds/smokekaari.webp");
    background-size: cover;
    background-position-x: 90%;
    background-repeat: no-repeat;
    object-fit: cover;
  }

  .smoke-2-parallel-down {
    height: 1000px;
    margin-top: -620px;
    background-image: url("./../images/backgrounds/smokekupera.webp");
    background-size: cover;
    background-position-x: 90%;
    background-repeat: no-repeat;
    object-fit: cover;
  }

  .smoke-1-parallel-down {
    height: 1000px;
    margin-top: -630px;
    background-image: url("./../images/backgrounds/smokekaari.webp");
    background-size: cover;
    background-position-x: 47%;
    background-repeat: no-repeat;
    object-fit: cover;
  }

  .smoke-2-parallel-up {
    height: 800px;
    margin-top: -630px;
    background-image: url("./../images/backgrounds/smokekupera.webp");
    background-size: cover;
    background-position-x: 44%;
    background-repeat: no-repeat;
    object-fit: cover;
  }
}

@media only screen and (min-width: 1921px) {
  .smoke-1-parallel-up {
    margin-top: -690px;
  }

  .smoke-2-parallel-down {
    margin-top: -690px;
  }

  .smoke-1-parallel-down {
    margin-top: -690px;
  }

  .smoke-2-parallel-up {
    margin-top: -690px;
  }
}

@media only screen and (min-width: 2100px) {
  .smoke-1-parallel-up {
    min-height: 1300px;
    margin-top: -760px;
  }

  .smoke-2-parallel-down {
    min-height: 1300px;
    margin-top: -760px;
  }

  .smoke-1-parallel-down {
    min-height: 1300px;
    margin-top: -760px;
  }

  .smoke-2-parallel-up {
    min-height: 1300px;
    margin-top: -760px;
  }
}

@media only screen and (min-width: 2560px) {
  .smoke-1-parallel-up {
    min-height: 1900px;
    margin-top: -1100px;
  }

  .smoke-2-parallel-down {
    min-height: 1900px;
    margin-top: -1100px;
  }

  .smoke-1-parallel-down {
    min-height: 1900px;
    margin-top: -1100px;
  }

  .smoke-2-parallel-up {
    min-height: 1900px;
    margin-top: -1100px;
  }
}

@media only screen and (min-width: 3620px) {
}

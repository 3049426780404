@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 950px) {
  .music-section {
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url("./../../../../images/backgrounds/2_Blank_mobile.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: 17%;
    min-height: 900px;
  }

  .music-section-content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 0px;
    z-index: 1;
  }

  /*Header, icons*/

  .music-video-header {
    color: white;
    text-align: center;
  }

  .icon-wrapper {
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: center;
    margin-bottom: 40px;
  }

  /*Video carousel*/

  .music-video-player-and-header-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: -250px;
    margin-bottom: 0px;
  }

  .music-video-player-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0vw 0vw;
    margin-top: 30px;
  }

  .music-video-wrapper {
    padding: 2vw;
    height: 80%;
    width: 80%;
  }

  .music-section-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .music-header-img {
    display: none;
    background-image: url("./../../../../images/page_elements/Music_text.svg");
    background-size: contain;
    background-repeat: no-repeat;
    height: 50px;
    width: 100px;
  }

  .music-service-icons-wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .music-section-shift-carousel-forward-button {
    background-image: url("./../../../../images/page_elements/Karuselli_Arrow_Left.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: 50%;
    padding-right: 20px;
    cursor: pointer;
    height: 50px;
    width: 50px;
    transform: rotate(180deg);
  }

  .music-section-shift-carousel-backwards-button {
    background-image: url("./../../../../images/page_elements/Karuselli_Arrow_Left.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: 50%;
    padding-left: 20px;
    cursor: pointer;
    height: 50px;
    width: 50px;
  }

  /*Albums and singles section*/

  .releases-wrapper {
    display: flex;
    flex-direction: column;
  }

  .releases-and-icons-inner-wrapper {
    display: flex;
    flex-direction: column-reverse;
  }

  .albums-header {
    color: #ff9900;
    text-align: center;
    width: 100%;
    display: none;
  }

  .albums-shifter-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    justify-content: center;
    padding: 0vw 8vw;
  }

  .albums-wrapper {
    animation: fadeIn 3.5s;
    display: flex;
    flex-direction: column;
    gap: 0px;
    justify-content: center;
    align-items: center;
  }

  .albums-item {
    align-items: center;
    border: 1px solid #ff9900;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2vw 2vw 2vw 2vw;
    user-select: none;
    width: 50vw;
    height: auto;
  }

  .albums-item-header {
    color: white;
    margin: 10px;
  }

  .music-icon {
    width: 45px;
    height: 45px;
  }

  .music-section-text {
    display: none;
  }
}

@media only screen and (min-width: 950px) {
  @keyframes breatheSplatter1 {
    0% {
      transform: scale(1) translateX(0); /* Start at normal size and position */
    }
    50% {
      transform: scale(1.1) translateX(-10px); /* Gently grow and shift slightly left */
    }
    100% {
      transform: scale(1) translateX(0); /* Smoothly return to original size and position */
    }
  }

  @keyframes breatheSplatter2 {
    0% {
      transform: scale(1) translateX(0); /* Start at normal size and position */
    }
    50% {
      transform: scale(1.1) translateX(+10px); /* Gently grow and shift slightly left */
    }
    100% {
      transform: scale(1) translateX(0); /* Smoothly return to original size and position */
    }
  }

  .music-section {
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url("./../../../../images/backgrounds/2_Blank.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 20% 50%;
  }

  .music-section-splatter-1 {
    background-image: url("./../../../../images/page_elements/Splash_Cropped_3.svg");
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: auto;
    margin-top: -500px;
    width: 300px;
    height: 500px;
    z-index: 1;
    overflow-x: hidden;
    animation: breatheSplatter1 8s ease-in-out infinite;
  }

  .music-section-splatter-2 {
    background-image: url("./../../../../images/page_elements/Splash_Cropped_4.svg");
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -300px;
    width: 300px;
    height: 427px;
    z-index: 1;
    animation: breatheSplatter2 7s ease-in-out infinite;
    animation-delay: 6s;
  }

  .music-header-img {
    background-image: url("./../../../../images/page_elements/Music_text.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 90px;
    height: 74px;
    margin-bottom: -140px;
    margin-top: -235px;
  }

  .music-section-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
    z-index: 2;
  }

  .music-section-text-and-video-wrapper {
    margin-right: -50px;
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    gap: 50px;
    height: 800px;
    width: 1000px;
    z-index: 2;
  }

  /*Header, icons*/

  .music-video-header {
    display: none;
    color: white;
    text-align: center;
    margin: 0px 0px -20px 0px;
  }

  .icon-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 10px;
    gap: 5px;
  }

  .music-icon {
    width: 30px;
    height: 30px;
  }

  /*Video carousel*/

  .music-video-player-and-header-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .music-video-player-wrapper {
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 0vw 0vw;
    width: 100%;
  }

  .music-video-wrapper {
    padding: 0vw 2vw 2vw 0vw;
    height: 80%;
    width: 560px;
  }

  .music-section-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .music-service-icons-wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .music-section-carousel-wrapper {
    animation: fadeIn 3.5s;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .music-section-carousel-content-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    height: 100%;
    width: 100%;
  }

  .music-section-carousel-showcase-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    user-select: none;
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    user-select: none;
    width: 100%;
  }

  .music-section-shift-carousel-forward-button {
    background-image: url("./../../../../images/page_elements/Karuselli_Arrow_Left.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: 50%;
    padding-right: 20px;
    cursor: pointer;
    height: 40px;
    width: 40px;
    transform: rotate(180deg);
  }

  .music-section-shift-carousel-backwards-button {
    display: none;
    background-image: url("./../../../../images/page_elements/Karuselli_Arrow_Left.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: 50%;
    padding-left: 20px;
    cursor: pointer;
    height: 40px;
    width: 40px;
  }

  /*Albums and singles section*/

  .releases-and-icons-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .releases-and-icons-inner-wrapper {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
  }

  .releases-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .albums-shifter-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    padding: 0vw 0vw;
    width: 100%;
  }

  .albums-wrapper {
    animation: fadeIn 3.5s;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }

  .albums-header {
    color: #ff9900;
    margin: 0px 0px 30px 0px;
  }

  .albums-item {
    align-items: center;
    /*border: 1px solid #ff9900;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2px 2px 2px 2px;
    user-select: none;
    width: 129px;
    height: auto;
  }

  .albums-item-header {
    color: white;
    font-size: 10px;
    margin: 0;
  }

  .music-service-icons-wrapper {
    display: none;
    flex-direction: column-reverse;
    gap: 10px;
  }

  .music-section-text-and-music-wrapper {
    display: flex;
    flex-direction: column;
  }

  .music-section-text {
    margin: 0 !important;
    font-size: 15px;
    color: white;
    text-align: right;
    font-family: "SpaceGrotesk-Regular";
    width: 300px;
  }
}

@media only screen and (min-width: 1367px) {
}

@media only screen and (min-width: 1680px) {
}

@media only screen and (min-width: 2100px) {
  .music-section {
    min-height: 1800px;
  }

  .music-header-img {
    margin-top: -350px;
  }
}

@media only screen and (min-width: 2561px) {
}

@media only screen and (min-width: 3620px) {
}

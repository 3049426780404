/* Loader styles */
.loader {
  position: fixed;
  left: 20px;
  top: 0;
  width: 100%;
  height: 100%;
  background: #080808;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: opacity 0.5s ease-out;
}

/* Hide the loader once the page is fully loaded */
body.loaded .loader {
  opacity: 0;
  pointer-events: none;
}

body.loaded .app-content {
  opacity: 1;
  transition: opacity 2.5s ease-in;
}

/* Initial state of the app content */
.app-content {
  opacity: 0;
}

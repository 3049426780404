@font-face {
  font-family: "SpaceGrotesk-Regular";
  src: local("SpaceGrotesk-Regular"),
    url("./fonts/SpaceGrotesk-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "SpaceGrotesk-Bold";
  src: local("SpaceGrotesk-Bold"),
    url("./fonts/SpaceGrotesk-Bold.ttf") format("truetype");
}

#root {
  display: flex;
  align-items: center;
  justify-content: center;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #080808;
}

button {
  all: unset;
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  min-width: 320px;
  overflow-x: hidden;
  width: 100%;
}

ul {
  list-style-type: none;
}

i {
  list-style-type: none;
}

iframe {
  aspect-ratio: 16 / 9;
  height: auto;
  width: 100%;
  border: none;
}

a,
u {
  text-decoration: none;
  color: inherit;
}

h1 {
  font-family: "SpaceGrotesk-Bold";
}

h2 {
  font-family: "SpaceGrotesk-Bold";
}

h3 {
  font-family: "SpaceGrotesk-Bold";
}

h4 {
  font-family: "SpaceGrotesk-Bold";
}

h5 {
  font-family: "SpaceGrotesk-Bold";
}

p {
  font-family: "SpaceGrotesk-Regular";
}

@media only screen and (min-width: 2561px) {
  body {
    max-width: 3620px;
    align-items: center;
    margin: auto;
  }
}

@media only screen and (max-width: 649px) {
  .store-section {
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url("./../../../../images/backgrounds/5_Blank.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: 45%;
  }

  .store-section-splatter-1 {
    background-image: url("./../../../../images/page_elements/Splash_Cropped_3.svg");
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: auto;
    margin-top: -600px;
    width: 300px;
    height: 500px;
    z-index: 1;
  }

  .store-section-splatter-2 {
    background-image: url("./../../../../images/page_elements/Splash_Cropped_2.svg");
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -300px;
    width: 300px;
    height: 400px;
    z-index: 1;
  }

  .store-section-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -200px;
    margin-bottom: 150px;
    height: 700px;
    z-index: 1;
  }

  .store-header-img {
    display: flex;
    align-self: center;
    background-image: url("./../../../../images/page_elements/Store_Header.svg");
    background-size: contain;
    background-repeat: no-repeat;
    height: 63px;
    width: 107px;
    z-index: 1;
  }

  .store-intro-text {
    color: white;
    display: none;
  }

  .apparel-header {
    color: #ff9900;
  }

  .shifter-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }

  .apparel-section-shift-carousel-forward-button {
    background-image: url("./../../../../images/page_elements/Karuselli_Arrow_Left.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: 50%;
    padding-right: 20px;
    cursor: pointer;
    height: 40px;
    width: 40px;
    transform: rotate(180deg);
  }

  .apparel-section-shift-carousel-backwards-button {
    background-image: url("./../../../../images/page_elements/Karuselli_Arrow_Left.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: 50%;
    padding-left: 20px;
    cursor: pointer;
    height: 40px;
    width: 40px;
  }

  .apparel-shifter-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .apparel-item-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .apparel-desktop-item-1 {
    display: none;
  }
  .apparel-desktop-item-2 {
    display: none;
  }
  .apparel-desktop-item-3 {
    display: none;
  }
  .apparel-desktop-item-4 {
    display: none;
  }

  .apparel-shifter-content-wrapper {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  .apparel-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 150px;
    width: 200px;
    margin: 1vw;
    overflow: hidden; /* Important: This ensures content doesn't spill out */
  }

  .apparel-text {
    color: white;
    text-align: center;
    padding: 0vw 8vw;
  }

  .apparel-picture {
    animation: fadeIn 3.5s;
    object-fit: contain; /* This will ensure the image fits without being cropped */
    width: 100%;
    height: 100%;
  }

  .apparel-item-header {
    color: white;
  }

  .apparel-item-price {
    text-align: center;
    color: white;
  }
}

@media only screen and (min-width: 650px) {
  .store-section {
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url("./../../../../images/backgrounds/5_Blank.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 45% 50%;
    height: 1400px;
  }

  .store-section-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -40px;
    min-height: 800px;
    z-index: 1;
  }

  .store-section-intro-and-catalogue-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 837px;
  }

  .store-header-img {
    display: flex;
    align-self: center;
    background-image: url("./../../../../images/page_elements/Store_Header.svg");
    background-size: contain;
    background-repeat: no-repeat;
    height: 80px;
    width: 90px;
    z-index: 1;
  }

  .store-intro-text {
    align-self: center;
    color: white;
    font-size: 15px;
    text-align: center;
  }

  .apparel-header {
    color: #ff9900;
  }

  .shifter-and-text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .apparel-shifter-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .apparel-section-shift-carousel-forward-button {
    background-image: url("./../../../../images/page_elements/Karuselli_Arrow_Left.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: 50%;
    padding-right: 20px;
    cursor: pointer;
    height: 40px;
    width: 40px;
    transform: rotate(180deg);
    margin-bottom: 40px;
  }

  .apparel-section-shift-carousel-backwards-button {
    background-image: url("./../../../../images/page_elements/Karuselli_Arrow_Left.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: 50%;
    padding-left: 20px;
    cursor: pointer;
    height: 40px;
    width: 40px;
    margin-bottom: 40px;
  }

  .apparel-shifter-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }

  /*Hide mobile views*/
  .apparel-item {
    display: none;
  }

  .apparel-item-header {
    display: none;
  }

  .apparel-item-price {
    display: none;
  }

  /*Set desktop items*/

  .apparel-desktop-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 220px;
  }

  .apparel-desktop-picture-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 150px;
    width: 200px;
    margin: 1vw;
    overflow: hidden;
  }

  .apparel-desktop-picture {
    animation: fadeIn 3.5s;
    object-fit: contain; /* This will ensure the image fits without being cropped */
    width: 100%;
    height: 100%;
  }

  .apparel-desktop-item-1 {
  }

  .apparel-desktop-item-2 {
  }

  .apparel-desktop-item-3 {
  }

  .apparel-desktop-item-4 {
  }

  .apparel-desktop-item-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 220px;
  }

  .apparel-desktop-item-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .apparel-desktop-item-header {
    white-space: normal;
    word-wrap: break-word;
    color: white;
    margin: 0;
  }

  .apparel-desktop-item-button-and-price {
    display: flex;
    flex-direction: column-reverse;
  }

  .apparel-desktop-item-button {
    display: none;
    color: white;
    align-self: center;
    border: 2px solid #ff9900;
    border-radius: 12px;
    color: white;
    padding: 3px 20px;
    font-family: "SpaceGrotesk-Bold";
    margin: 0;
  }

  .apparel-desktop-item-price {
    text-align: center;
    color: white;
    margin: 0;
  }
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1367px) {
  .store-section-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -40px;
    min-height: 959px;
    z-index: 1;
  }
}

@media only screen and (min-width: 1680px) {
}

@media only screen and (min-width: 2100px) {
  .store-section {
    min-height: 1800px;
  }

  .store-header-img {
    margin-top: -850px;
  }
}

@media only screen and (min-width: 2561px) {
}

@media only screen and (min-width: 3620px) {
}
